import { Slider } from '@progress/kendo-react-inputs'
import React from 'react'
import * as BillingUtils from 'shared_server_client/types/billing_plan'
import * as UsageStats from 'shared_server_client/types/usage_stats'
import './styles.css'

export interface SliderOption {
  basePrice: number,
  messagePrice: number,
  totalPrice: number,
  totalMessages: number,
}

export interface OnChangeEvent {
  selected: SliderOption
}

export type OnChange = (e: OnChangeEvent) => void

interface PricingSliderProps {
  currentPlan: BillingUtils.CurrentPlan,
  usage: UsageStats.UsageStats,
  plan: BillingUtils.BillingPlan,
  onChange: OnChange,
  selected?: SliderOption,
}

interface PricingSliderState {
  contactPrice: number,
  frequency: BillingUtils.BillingFrequency,
  includedMessages: number,
  maxContacts: number,
  selectedMessages: number,
  totalPrice: number,
}

export class PricingSliderComponent extends React.Component<PricingSliderProps, PricingSliderState> {
  constructor(props: PricingSliderProps) {
    super(props)
    this.state = {
      contactPrice: 0,
      frequency: BillingUtils.BillingFrequency.MONTHLY,
      includedMessages: 0,
      maxContacts: 0,
      selectedMessages: 0,
      totalPrice: 0,
    }
  }
  public componentDidMount() {
    const tier: UsageStats.TierPrice =
      BillingUtils.getProjectedContactPricing(this.props.plan, this.props.usage.contactCount)
    this.setState({
      contactPrice: tier.cost,
      frequency: this.props.plan.frequency,
      includedMessages: BillingUtils.getCurrentPlanIncludedMessages(this.props.plan),
      maxContacts: tier.maximum,
      selectedMessages: BillingUtils.getCurrentPlanIncludedMessages(this.props.plan),
      totalPrice: this.props.plan.monthly_cost,
    })
  }
  public render() {
    return(
      <div className="pricing-slider">
        <div className="plan-price">
          ${this.state.totalPrice + this.state.contactPrice} / month
        </div>
        {this.state.maxContacts <= 0 ? <div></div> :
        <div>
          <div className="pricing-details">
            Price for
            {this.props.currentPlan.billing_system_code === 'zoho' ? ' up to ' : ' additional '}
            {this.state.maxContacts.toLocaleString()} contacts.<br/>  You
            currently have {this.props.usage.contactCount.toLocaleString()} contacts.  Your
            pricing will automatically be adjusted to reflect growth in your list size.
          </div>
        </div>}
        {this.state.includedMessages <= 0 ? <div></div> :
        <div>
          <div className="pricing-details">
            Price for {this.state.includedMessages.toLocaleString()} messages/month
          </div>
          <Slider
              className="rasa-slider"
              buttons={false}
              step={1000}
              defaultValue={this.state.selectedMessages}
              min={this.state.includedMessages}
              max={BillingUtils.getCurrentPlanMaxMessages(this.props.plan)}
              onChange={
                (e) => {
                  const selectedOption = this.getSelectedPlanMessagePricing(e.value)
                  this.props.onChange({selected: selectedOption})
                }
              }>
          </Slider>
        </div>}
      </div>
    )
  }

  private getSelectedPlanMessagePricing = (messageCount: number): SliderOption => {
    messageCount = Math.round(messageCount / 1000) * 1000
    const basicPrice = this.state.frequency === BillingUtils.BillingFrequency.MONTHLY
      ? this.props.plan.monthly_cost : this.props.plan.annual_cost
    const messageOveragePrice = BillingUtils.getSelectedPlanOverageCost(this.props.plan, messageCount)
    this.setState({
      selectedMessages: messageCount,
      totalPrice: basicPrice + messageOveragePrice,
    })
    return {
      totalMessages: messageCount,
      basePrice: basicPrice,
      messagePrice: messageOveragePrice,
      totalPrice: basicPrice + messageOveragePrice,
    }
  }
}
