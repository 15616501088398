import React from 'react'
import { AbstractPanelBar } from '../component'

import './_styles.scss'

export class ReducedMobilePanelBar extends AbstractPanelBar {

  public render() {
    return <div className="mobile-panelbar"/>
  }

}
