export interface NotificationProps {
  onCommunityChange?: any,
  push: any,
  showOnlyWhenMultiple?: boolean,
  showLabel?: boolean,
}

export interface NotificationState {
  notifications: any[],
  isLoading: boolean,
  isSuperUser: boolean,
  selected: any,
  showList: boolean,
}

export interface NotificationRecord {
  notification_id: number,
  is_read: boolean,
  title: string,
  description: string,
  displayTitle: string,
  displayDescription: string,
  notification_type: string,
  notification_type_description: string,
  community_id: string,
  created_at: string,
  link_url: string,
  created?: string
}
export interface NotificationLogPayload {
  key: string,
  id: number,
  title: string,
  timestamp: number,
}

export enum NotificationType {
  ACCOUNT_NOTIFICATION = "account-notification",
  CONTENT_READY = "content-ready",
  NEWSLETTER_NOTIFICATION = "newsletter-notification",
  SCHEDULE_PAUSE = "schedule-pause",
}