import {isEmpty} from 'lodash'
import {filter, negate, pipe} from 'lodash/fp'
import * as React from 'react'
import * as Flash from './flash'
import {notifications} from './notifications'
import './styles.css'
import {Notification} from './types'

export const showNotificationArea = pipe(
  filter(Boolean),
  negate(isEmpty),
)

export const Notifications = () => <div className="notification-area">
  {notifications.map((n: Notification, i: number) =>
    <div key={i}>{React.createElement(n.content, {})}</div>)}
  <Flash.FlashMessage/>
</div>
