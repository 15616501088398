import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import * as React from 'react'
import {
  createNotification,
  Notification,
  NotificationType,
} from './types'

const ValidatedNotificationContent = (props) => {
  return <div className="validated-domain-notice">
    Your domain is validated! Your newsletters will now be sent from <i>{props.context.address}</i>.
    <br/><br/>
    Visit the 'send from' tab in the &nbsp;
    <a href="#" onClick={() => props.push(DashboardMenuOption.emailDesign)}>
      Design page
    </a>
    &nbsp; if you'd like to edit your 'send from' email address.
  </div>

}

export const ValidatedDomainNotification: Notification = createNotification(
  'VALIDATED_DOMAIN',
  ValidatedNotificationContent,
  {
    actions: ['DOMAIN_VALIDATED'],
  },
  NotificationType.success,
)
