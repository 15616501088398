import React from 'react'

interface NotificationIconProps {
  count: number,
}

export class NotificationIcon extends React.Component<NotificationIconProps> {
  constructor(props: NotificationIconProps) {
    super(props)
  }
  public render() {
    return (
      <div className="notification-icon-container">
        {this.props.count > 0
        ? <div className='notification-count-wrapper'>
            <span className="notification-count">{this.props.count}</span>
          </div>
        : null}
        <div className='notification-icon-wrapper'>
          <i className="fa fa-bell" aria-hidden="true"></i>
        </div>
      </div>
    );
  }
}
