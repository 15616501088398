import { Component } from 'react'
import { PanelBarOption, PanelBarProps, PanelBarState } from './types'

//// Component Properties
//// title - title to display on top of control
//// subTitle - smaller text under the title
//// itemList - array of PanelBarOption classes to show - each array element can have
////      name, icon, route, etc see the info on the PanelBarOption class
//// selectedItem - ROUTE(string) of the item to show
//// onChange - callback function to listen to changes in the component, receives
////      a parameter (e) which contains the item that was selectced
//// headerIcon - optional image icon for the header of the panel
//// itemIcon - optional image icon for each individual item in the panel

export abstract class AbstractPanelBar extends Component<PanelBarProps, PanelBarState> {
  private _lastOption: PanelBarOption

  constructor(props: PanelBarProps) {
    super(props)
    this.state = {
      selectedItem: props.selectedItem,
    }
    this._updateComponentState(true)
  }

  public componentDidUpdate() {
    this._updateComponentState(false);
  }

  protected handleNav(e: PanelBarOption) {
    if (e.route !== this.state.selectedItem.route) {
      // change our internal state and show/hide the appropriate component
      // this is automatically bound to the visibility via the JSX lower
      // in this file that requires selectedItem to match their key
      this.setState({...this.state, selectedItem: e})
      if (this.props.onChange) {
        this.props.onChange(e);
      }
    }
  }

  protected isMatch(selectedItem: PanelBarOption, item: PanelBarOption): boolean {
    return selectedItem && (selectedItem.route === item.route)
  }

  private _updateComponentState(bDirect: boolean) {
    const option: PanelBarOption = this.props.selectedItem
    if (option !== this._lastOption || option !== this.state.selectedItem) {
      // only update state if we got a different value from outside of the component
      // compared to the last props.selectedItem we got
      const newState = {
        itemList: this.props.itemList,
        selectedItem: this.props.itemList.find((item) =>
                      (item.route.toLowerCase() === option.route.toLowerCase()))}
      if (bDirect) {
        this.state = newState
      } else {
        this.setState(newState)
      }
    }
    this._lastOption = option
  }

}
