import classnames from 'classnames'
import { isEmpty } from 'lodash'

import * as FlashActions from 'components/flash/actions'
import * as FlashTypes from 'components/flash/types'
import * as React from 'react'
import {connect} from 'react-redux'
import {CSSTransition} from 'react-transition-group'
import { Expire } from 'shared/notifications/expire'

interface FlashMessageProps extends FlashTypes.WrappedState {
  close: any,
}
export const FlashMessage = connect(
  ({flash}: FlashTypes.WrappedState) => ({flash}),
  {
    close: () => FlashActions.clearFlash(),
  },
)(({flash, close}: FlashMessageProps) => isEmpty(flash.message) ? null :
<CSSTransition
  appear={true}
  in={true}
  classNames="slide"
  timeout={1000}>
  <Expire delay={5000}>
    <div onClick={close}
      className={`clickable-item ${flash.color} ${classnames('notification', {'is-open': !isEmpty(flash.message)})}`}>
      <div className="content">
        {flash.message}
      </div>
      <div className="dismiss">
        <span>x</span>
      </div>
    </div>
  </Expire>
</CSSTransition>)
