import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import get from 'lodash/get'
import * as React from 'react'
import {USER_INIT} from 'user/constants'

import {
  createNotification,
  Notification,
  NotificationType,
} from './types'

const UnverifiedNotificationContent = ({push}: any) =>
  <p className="unverified-notice">
    You must verify your email address before your can start sending your newsletter.
    <br/><br/>
    <a href="#" onClick={() => push(DashboardMenuOption.settings)}>
      Click here to confirm email address and request the verification link.
    </a>
  </p>

export const UnverifiedEmailNotification: Notification = createNotification(
  'UNVERIFIED_EMAIL',
  UnverifiedNotificationContent,
  {
    actions: [USER_INIT],
    filter: ((action: any, state: any) => {
      return !get(state.value, 'app.params.signup') && action.user.person && !action.user.person.verified
    }),
  },
  NotificationType.warning)
