import { DropdownComponent, DropdownOption, OnChangeEvent } from 'components/dropdown/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { copyToClipboard } from 'generic/utility'
import * as React from 'react'
import { Button, Input, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { ChatPayload, ChatResponse, GenerationType } from 'shared_server_client/types/generative_ai'

import * as Constants from './constants'
import * as Data from './data'
import * as Types from './types'

export interface Props {
  communityIdentifier: string,
  description: string,
  aiPromptType?: string,
  responsesTitle?: string,
  selectors?: Types.SelectorType[],
}

export interface State {
  emoji: DropdownOption,
  error: string,
  format: DropdownOption,
  isGenerating: boolean,
  language: DropdownOption,
  length: DropdownOption,
  title: string,
  tone: DropdownOption,
  url: string,
  versionDisplay: number,
  versionGenerate: number,
  versions: string[],
}

export const baseEmptyState: State = {
  emoji: Constants.EmojiDropdownOptions[0],
  error: '',
  format: Constants.FormatTypeOptions[0],
  isGenerating: false,
  language: Constants.LanguageDropdownOptions[0],
  length: Constants.LengthDropdownOptions[0],
  title: '',
  tone: Constants.ToneDropdownOptions[0],
  url: '',
  versionDisplay: 0,
  versionGenerate: 0,
  versions: [''],
}

export class GenerateTextComponent extends React.Component<Props, State> {

  public static contextType = RasaContext

  private responseRef

  constructor(props: Props) {
    super(props)
    this.responseRef = React.createRef()
    this.state = baseEmptyState
  }

  protected renderSelector = (selector: Types.SelectorType, className: string) => {
    if ( selector ) {
      return <div className={className}>
          <h5>{selector.title}</h5>
          <DropdownComponent data={selector.options}
                             selected={this.state[selector.key].key}
                             onChange={(e) => this.setSelectorType(e, selector)}/>
      </div>
    } else {
      return <div className={className}/>
    }
  }

  public render () {

    return <div className="ai-generate body-container generated-text-modal-body">
      <div className="left-side-container">
        {this.props.selectors.map((selector: Types.SelectorType) => {
          return <div className="first-row-container flex-container">
            {this.renderSelector(selector, 'left-side-container')}
          </div>
        })}
      </div>
      <div className="right-side-container full-height">
        <div className="first-row-container">
          <h5>{this.responsesTitle()}</h5>
          <Nav tabs>
            { this.state.versions.map((message, index) => (
            <NavItem key={index}>
              <NavLink className={this.state.versionDisplay === index ? 'active' : ''}
                       onClick={() => this.setVersion(index)}>
                <span>
                  {(this.state.isGenerating && this.state.versionGenerate === index) ? '* ' : ''}Version {index + 1}
                </span>
              </NavLink>
            </NavItem>
            ))}
          </Nav>
          <TabContent>
            <TabPane>
              <Input className="response-text" type="textarea" id="response-text"
                     ref={this.responseRef}
                     value={this.state.versions[this.state.versionDisplay]}
                     onFocus={(e) => e.target.select()}
                     onChange={this.setMessage}/>
            </TabPane>
            <div className="actions flex-centered">
                <Button disabled={!this.hasCurrentVersion()} onClick={this.copyCurrentText}>
                  <span>Copy</span>
                </Button>
                <Button disabled={this.state.isGenerating} onClick={this.generateText}>
                  <span>Generate</span>
                </Button>
                <Button disabled={!this.hasCurrentVersion()} onClick={this.newVersion}>
                  <span>New</span>
                </Button>
            </div>
          </TabContent>
        </div>
        { this.state.isGenerating && <div className="second-row-container">
          <Loading size="32"></Loading>
        </div>}
        { this.state.error && <div className="second-row-container">
          <span className="error">{this.state.error}</span>
        </div>}
      </div>
    </div>
  }

  protected responsesTitle = (): string => this.props.responsesTitle || 'Responses'

  // Modal overrides
  protected saveDisabled(data: any): boolean {
    return !this.hasCurrentVersion()
  }

  protected secondActionDisabled(data: any): boolean {
    return !this.hasCurrentVersion()
  }

  protected getGenerationType(): GenerationType {
    return GenerationType.TEXT
  }

  protected buildChatPayload(): ChatPayload {
    return {
      aiPromptType: this.props.aiPromptType,
      content: this.props.description,
      emojis: this.state.emoji.value,
      language: this.state.language.value,
      length: this.state.length.value,
      prompt: this.props.description,
      promptType: this.state.format.value,
      topics: [],
      title: this.state.title,
      tone: this.state.tone.value,
      url: '',
    }
  }

  private setMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      versions: this.state.versions.map((message, index) => {
        if ( index === this.state.versionDisplay ) {
          return event.target.value
        } else {
          return message
        }
      }),
    })
  }

  private setVersion = (version: number) => {
    this.setState({
      versionDisplay: version,
    })
  }

  private setSelectorType = (event: OnChangeEvent, selector: Types.SelectorType) => {
    this.setState({
      ...this.state,
      [selector.key]: event.selected,
    })
  }

  private updateChatMessage: Data.ChatCallback = (response: ChatResponse) => {
    if ( !response ) {
      this.setState({
        isGenerating: false,
      })
    } else {
      if ( response.done || response.error ) {
        this.setState({
          isGenerating: false,
          error: response.error,
        })
      }
      if ( response.message ) {
        this.setState({
          versions: this.state.versions.map((message, index) => {
            if ( index === this.state.versionGenerate ) {
              return message + response.message
            } else {
              return message
            }
          }),
        })
      }
    }
  }

  private getCurrentText = (): string => {
    return this.state.versions[this.state.versionDisplay]
  }

  private hasCurrentVersion(): boolean {
    return !this.state.isGenerating && this.getCurrentText().length > 0
  }

  private copyCurrentText = (): void => {
    copyToClipboard(this.getCurrentText())

    // YUCK.  In order to select the text, I need the DOM element.  But react REF
    // only gives me a React element.  focus doesnt' invoke the onFocus method automatically.
    // So this hack - call the react focus, then get the DOM element and pass it to the onFocus
    // method directly.
    this.responseRef.current.focus()
    this.responseRef.current.props.onFocus({target: document.getElementById('response-text')})
  }

  private newVersion = (): void => {
    // For now, overriding save to mean "open a new tab"
    this.setState({
      versions: this.state.versions.concat(['']),
      versionDisplay: this.state.versions.length,
      versionGenerate: this.state.versions.length,
    })
  }

  private generateText = () => {
    this.setState({
      isGenerating: true,
      error: '',
      versionGenerate: this.state.versionDisplay,
      versions: this.state.versions.map((message, index) => {
        if ( index === this.state.versionDisplay ) {
          return ''
        } else {
          return message
        }
      }),
    }, () => {
      Data.streamChatResponse(
        this.props.communityIdentifier,
        this.buildChatPayload(),
        this.getGenerationType(),
        this.updateChatMessage)
    })
  }

}
