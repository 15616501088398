import * as React from 'react'
import {connect} from 'react-redux'
import * as Router from 'router'
import * as Notifications from 'shared/notifications'

export type Filter = (action: any, state: any) => boolean

export const ALWAYS_ALLOW: Filter = (action: any, state: any) => true

export interface Trigger {
  actions: string[]
  filter?: Filter
}

export enum NotificationType {
  default = 'default',
  warning = 'warning',
  error = 'error',
  notice = 'notice',
  success = 'success',
}

export interface Notification {
  actions: Notifications.NotificationActions,
  autoHide?: number,
  content: any,
  key: string,
  trigger: Trigger,
  type: NotificationType,
}

const createComponent = (
  key: string,
  content: any,
  actions: Notifications.NotificationActions,
  type: NotificationType,
): any => {
  const Component = Notifications.createNotificationComponent(key)
  // For the child, automatically add Push to enable link handling within the components
  const ConnectedChild = connect(
    (state: any) => ({context: ((state.notifications[key] || {}).context) || {}}),
    {
      push: Router.push,
    },
  )(content)
  return connect(
    () => ({}),
    {
      close: () => actions.close(),
    },
  )(({close}: any) => <Component onClick={close} className={type + ' clickable-item'}>
    <ConnectedChild/>
  </Component>)
}

export const createNotification =
  (key: string, content: any, trigger: Trigger, type: NotificationType): Notification => {
  const actions = Notifications.createActions(key)
  return {
    actions,
    content: createComponent(key, content, actions, type),
    key,
    trigger: {
      actions: trigger.actions,
      filter: trigger.filter || ALWAYS_ALLOW,
    },
    type,
  }
}
