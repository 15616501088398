import React from 'react'
import { Loading } from 'components/loading'
import { NotificationRecord } from './types';
import { isFalsey, isTruthy } from 'generic/utility';

interface NotificationItemProps {
  item: NotificationRecord,
  onClick?: any,
  readToggle?: any,
}
interface NotificationItemState {
  isLoading: boolean
}

export class NotificationItem extends React.Component<NotificationItemProps, NotificationItemState> {
  constructor(props: NotificationItemProps) {
    super(props)
    this.state = {
      isLoading: false,
    }
  }
  public render() {
    const readTitle = isTruthy(this.props.item.is_read) ? 'Mark as unread' : 'Mark as read'
    return <div className='notification-item-wrapper'>{this.state.isLoading
    ? <Loading size="64" />
    :
      <div className='notification-description'>
        <div className='notification-item-read' title={readTitle} onClick={this.readToggle}>
          {isFalsey(this.props.item.is_read) ? <div className="notification-item-indicator"></div> : null}
        </div>
        <div className='notification-item' key={this.props.item.notification_id} onClick={this.readNotification}>
          <div className='notification-item-header'>
            <div className='notification-item-type'
                 onClick={this.itemClick} dangerouslySetInnerHTML={{ __html: this.props.item.displayTitle }}></div>
            <div className='notification-item-created' onClick={this.itemClick}>{this.props.item.created_at}</div>
          </div>
          <div className='notification-item-title' onClick={this.itemClick} dangerouslySetInnerHTML={{ __html: this.props.item.displayDescription }}></div>
        </div>
      </div>}
    </div>
  }

  private itemClick = (e) => {
    if (e.target.tagName.toLowerCase() === 'a') {
        e.stopPropagation()
        return
    }
    if (this.props.onClick) {
      this.props.onClick(this.props.item)
    }
  }
  private readNotification = () => {
    if (this.props.readToggle && !this.props.item.is_read) {
      this.setState({isLoading: true})
      this.props.readToggle(this.props.item, !this.props.item.is_read)
        .then(() => {
          this.setState({isLoading: false})
        })
    }
  }
  private readToggle = () => {
    if (this.props.readToggle) {
      this.setState({isLoading: true})
      this.props.readToggle(this.props.item, !this.props.item.is_read)
      .then(() => {
        this.setState({isLoading: false})
      })
    }
  }
}
