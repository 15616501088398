import React from 'react'
import { AbstractPanelBar } from '../component'
import { PanelBarOption } from '../types'

import './_styles.scss'

export class MobilePanelBar extends AbstractPanelBar {

  public render() {
    // const visible = this.props.itemList.filter((item) => !item.hide)
    // const selected = visible.filter((item) => this.isSelected(item))
    //                         .concat(visible.filter((item) => item.route === ''))[0]
    return <div className="mobile-panelbar">
      <div className="mobile-panel-panelbar">
          <span className="mobile-header-icon">
            {this.props.headerIcon ? this.props.headerIcon : ''}
          </span>
          <div className="mobile-panel-section-header-text mobile-panel-indent">
          {this.props.title}
        </div>
        <div className="mobile-panel-options">
          {this.outputOptions()}
        </div>
      </div>
    </div>
  }

  private outputOptions() {
    return this.props.itemList
    .filter((item) => !item.hide)
    .map((item: PanelBarOption) => (
      <div key={item.name} className="mobile-panelbar clickable-item">
        {this.isMatch(this.state.selectedItem, item) && (
          <div className="mobile-panel-item-selected" onClick={(e: any) => this.handleNav(item)}>
              <div className="mobile-icon-wrapper">
                {item.icon ? item.icon : ''}
              </div>
              <div className="mobile-panel-item-text mobile-panel-item-text-selected">
                <div className="mobile-item-name">
                  {item.name}
                </div>
              </div>
          </div>)}
          {!this.isMatch(this.state.selectedItem, item) && (
          <div className="mobile-panel-item" onClick={(e: any) => this.handleNav(item)}>
            <div className="mobile-icon-wrapper">
              <div className="mobile-icon">
              {item.icon ? item.icon : ''}
              </div>
            </div>
            <div className="mobile-panel-item-text clickable-item">
              {item.name}
            </div>
          </div>)}
      </div>))
  }
}
