import { IN_APP_NOTIFICATION_ACTION } from './constants'
import { NotificationLogPayload } from './types'

export const saveNotificationEvent = (eventNme: string, payload: NotificationLogPayload) => {
  return {
    type: IN_APP_NOTIFICATION_ACTION,
    data: {
      name: eventNme,
      payload:{
        key: payload.key,
        notificationId: payload.id,
        title: payload.title,
        timestamp: payload.timestamp
      }
    }
  }
}