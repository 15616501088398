import { DropdownOption } from 'components/dropdown/component'

import * as Types from './types'

export const LengthDropdownOptions: DropdownOption[] = [
  {key: 'Short', value: 30},
  {key: 'Medium', value: 75},
  {key: 'Long', value: 125},
  {key: 'Verbose', value: 200},
]

export const LanguageDropdownOptions: DropdownOption[] = [
  {key: 'English', value: 'English'},
  {key: 'French', value: 'French'},
  {key: 'Spanish', value: 'Spanish'},
  {key: 'German', value: 'German'},
  {key: 'Portugese', value: 'Portugese'},
  {key: 'Greek', value: 'Greek'},
]

export const PromptTypeDropdownOptions: DropdownOption[] = [
  {key: 'Introduction', value: 'Introduction'},
  {key: 'Conclusion', value: 'Conclusion'},
  {key: 'Promotional', value: 'Promotion'},
]

export const FormatTypeOptions: DropdownOption[] = [
  {key: 'HTML', value: 'HTML'},
  {key: 'Text', value: 'text'},
]

export const EmojiDropdownOptions: DropdownOption[] = [
  {key: 'No Emojis', value: 0},
  {key: 'Use Emojis', value: 1},
  {key: 'More Emojis!', value: 2},
]

export const ToneDropdownOptions: DropdownOption[] = [
  { key: 'Friendly', value: 'friendly' },
  { key: 'Serious', value: 'serious' },
  { key: 'Humorous', value: 'humorous'},
  { key: 'Medeival', value: 'medeival' },
  { key: 'Poetic', value: 'poetry' },
]

export const ToneDropdownSelector: Types.SelectorType = {
  title: 'Tone',
  options: ToneDropdownOptions,
  key: 'tone',
}

export const LengthDropdownSelector: Types.SelectorType = {
  title: 'Length',
  options: LengthDropdownOptions,
  key: 'length',
}

export const EmojiDropdownSelector: Types.SelectorType = {
  title: 'Emojis',
  options: EmojiDropdownOptions,
  key: 'emoji',
}

export const LanguageDropdownSelector: Types.SelectorType = {
  title: 'Language',
  options: LanguageDropdownOptions,
  key: 'language',
}

export const FormatDropdownSelector: Types.SelectorType = {
  title: 'Format',
  options: FormatTypeOptions,
  key: 'format',
}

export const ALL_SELECTOR_TYPES: Types.SelectorType[] = [
  LengthDropdownSelector,
  ToneDropdownSelector,
  EmojiDropdownSelector,
  LanguageDropdownSelector
]
