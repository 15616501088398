import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { HeaderComponent } from 'components/header/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import * as React from 'react'
import { Button, Input } from 'reactstrap'
import { RecommendationType } from './constants'

interface State {
  data: any,
  input: string
  isThinking: boolean
  selectedCommunity: string,
}

const emptyState: State = {
  data: null,
  input: 'Im interested in artificial intelligence and email marketing and how the two can work together.  Even better if its about distance running.',
  isThinking: false,
  selectedCommunity: '',

}
class RexDemonstrationComponent extends RasaReactComponent<any, State> {
  public static contextType = RasaContext;

  constructor(props: any) {
    super(props, "rexDemonstration", emptyState)
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.setState({
        selectedCommunity: activeCommunity.communityId,
      })
    })
  }

  public render = () => {
    return <div className="rex-demo">
      <HeaderComponent
        title={'Rex Demonstration'}
        subTitle='Let Rex Help you!'
        description={[
          'Enter an email address, a URL, or some text, and let Rex recommend some stuff.'
        ]}
      />
      <div>
        <Input type="textarea"
               value={this.state.input}
               disabled={this.state.isThinking}
               onChange={(e) => this.setState({input: e.target.value})} />
        <Button disabled={!this.canAsk()}
                onClick={this.askRex}>
          Ask Rex!
        </Button>
      </div>
    { this.state.isThinking ? <Loading size="32"/>
    : (this.state.data && <div>
       { Object.keys(this.state.data).map((key) => this.dataTable(key) ) }
     </div>)
    }
    </div>
  }

  private columnsFor = (key: string) => {
    if ( key === RecommendationType.PERSON) {
      return [
        { field: "id" },
        { field: "first_name" },
        { field: "last_name" },
        { field: "address", title: "email"},
        { field: "status", title: "subscribed" },
        { field: "last_click" },
        { field: "last_open" },
        { field: "name", title: "community"}
      ]
    } else if ( key === RecommendationType.ARTICLE ) {
      return  [
        { field: "title" },
        { field: "description" },
        { field: "url" },
        { field: "image_url" },
        { field: "name", title: "source" },
      ]
    } else {
      return [
        { field: "id" },
      ]
    }
  }

  private dataTable = (key: string) => {
    return <div key={key}>
      <h2>{key}</h2>
      <Grid data={this.state.data[key]} >
        { this.columnsFor(key).map((field) =>
          <GridColumn field={field.field} title={field.title || field.field.replace("_", " ")}/>
        )}
          <GridColumn field="score"/>
          <GridColumn field="model"/>
          <GridColumn field="engine"/>
        </Grid>
      </div>
  }

  private canAsk = (): boolean => {
    return ( this.state.input || '').length > 5 && !this.state.isThinking
  }

  private askRex = () => {
    this.setState({
      data: null,
      isThinking: true
    }, () => {
      AjaxWrapper
        .ajax(`${AjaxWrapper.getServerUrl()}/rex/${this.state.selectedCommunity}/find`, HttpMethod.POST, {
          application: 'dashboard',
          input: this.state.input,
          identifier: this.state.selectedCommunity,
          limit: 5
        })
        .then((data) => this.setState({
          data,
          isThinking: false,
        }))
        .catch((error) =>  this.setState({
          data:  error,
          isThinking: false,
        }))
    })
  }
}

export const RexDemonstration = GenericRedux.createConnect(
  RexDemonstrationComponent,
  "rex_demonstration",
)
