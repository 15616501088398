import * as ReduxObservable from 'redux-observable'
import * as Router from 'router'
import {filter, map, mergeMap} from 'rxjs/operators'
import {notifications} from './notifications'
import {Notification} from './types'
import { IN_APP_NOTIFICATION_ACTION } from '../notification/constants'
import * as GA from '../../google-analytics'

const clearNotifications = (action$, state$) =>
  action$.ofType(Router.PUSH)
  .pipe(
    mergeMap(() => notifications.map((n: Notification) => n.actions.close())),
  )

const buildEpic = (notification: Notification) => {
  return (action$, state$) => action$.ofType(...notification.trigger.actions).pipe(
    filter((action: any) => notification.trigger.filter(action, state$)),
    map((action: any) => notification.actions.open(action)),
  )
}

const inNotificationAction: ReduxObservable.Epic = (action$, state$, { history, user }) => {
  return action$.ofType(IN_APP_NOTIFICATION_ACTION)
    .pipe(
      map((action) => action.data),
      map((event: GA.Event4) => ({type: GA.SEND_EVENT_4, event})),
    )
}

export const epic = ReduxObservable.combineEpics(
  clearNotifications,
  inNotificationAction,
  ...notifications.map((n: Notification) => buildEpic(n)),
)
